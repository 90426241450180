.left {
    position: relative;
    width: 720px;
    background: url("../../public/left_paper.png");
    background-color: antiquewhite;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .left > * { 
    margin-left: 62.78px;
  }

  .left_pen {
    width: 140.89px;
    height: 118.78px;
    position: absolute;
    top: 133px;
    left: 230.78px;
    margin-left: 0,
  }

  .left_ariana {
    width: 202px;
    height: 202px;
    position: absolute;
    top: 72px;
    left: 407.78px;
    margin-left: 0,
  }
  
  .left_title {
    margin-top: 83px;
    font-size: 35px;
    width: 416px;
    height: 90px;
    display: inline-block;
    font-family: "Montagu Slab", serif;
    font-weight: 600;
    font-style: bold;
  }
  
  .left_data {
    font-size: 15px;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    color: #D1D1D1;
  }
  .left_pig_one {
    width: 587px;
    height: 205px; 
    margin-top: 15px;
  }
  

  .left_text {
    font-size: 15px;
    width: 587px;
    height: 76px;
    display: inline-block;
    font-family: "Montagu Slab", serif;
    font-weight: 200;
    text-align: justify
  }

  .left_paper {
    width: 720px;
    height: 550px;
    margin-left: 0px;
    margin-top: -60px;
  }

  .left_circle {
    width: 448px;
    height: 139px;
  }

  .left_title_two {
    text-align: center;
    margin-top: -105px;
    font-size: 35px;
    width: 416px;
    height: 90px;
    display: inline-block;
    font-family: "Montagu Slab", serif;
    font-weight: 600;
    font-style: bold;
  }

  .left_content {
    position: absolute;
    top: 558px;
    left: 86px;
    width: 416px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 4;
  }

  .button {
    all: unset; 
    cursor: pointer; 
    display: inline-block; 
    width: 324px;
    height: 58px;
    background-color: #212121;
    font-size: 30px;
    font-family: "Montagu Slab", serif;
    font-weight: 700;
    font-style: bold;
    color: white;
    text-align: center;
    margin-top: 18px;
  }

  .left_buttons {
    margin-top: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 25px;
  }

  .link {
    font-family: "Montagu Slab", serif;
    font-weight: 700;
    font-style: bold;
    color: #212121;
    font-size: 20px;
    cursor: pointer;
    text-decoration: underline;
  }

  .left_text_bottom {
    font-size: 15px;
    width: 587px;
    height: 114px;
    margin-top: -90px;
    display: inline-block;
    font-family: "Montagu Slab", serif;
    font-weight: 200;
    text-align: justify
  }

  .left_pageN {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 200;
    font-style: normal;
    color: #212121;
    margin-top: 19px;
    margin-bottom: 20px;
  }

  @media (max-width: 1439px) {
   
  }

  @media (max-width: 719px) {
    .left {
      width: 402px;
    }

    .left > * { 
      margin-left: 16px;
    }

    .left_title {
      font-size: 25px;
      width: 240px;
      height: 64px;
      margin-top: 50px;
    }

    .left_ariana {
      width: 143px;
      height: 143px;
      left: 214px;
      top: 80px;
    }

    .left_pen {
      width: 110.43px;
      height: 92.74px;
      top: 105px;
      left: 99px;
    }

    .left_data {
      font-size: 12px;
    }

    .left_pig_one {
      width: 370px;
      height: 161px;
      margin-top: 38px;
    }

    .left_text {
      width: 370px;
      height: 75px;
      font-size: 12px;
    }

    .left_content {
      width: 336.76px;
      top: 457px;
      left: 20px;
    }

    .left_paper {
      width: 460px;
      height: 350px;
      margin-left: -25px;
      margin-top: -25px;
    }

    .left_circle {
      width: 314px;
      height: 102px;
      z-index: 2;
    }

    .left_title_two {
      font-size: 25px;
      width: 240px;
      height: 64px;
      margin-top: -75px;
    }

    .button {
      font-size: 18px;
      width: 229.14px;
      height: 39px;
      margin-top: 10px;
    }

    .link {
      font-size: 14px;
    }

    .left_text_bottom {
      margin-top: -25px;
      width: 370px;
    }

    .left_pageN {
      margin-top: 60px;
      margin-bottom: 30px;
    }
  }