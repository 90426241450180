@import url('https://fonts.googleapis.com/css2?family=Montagu+Slab:opsz,wght@16..144,100..700&display=swap');

body {
  overscroll-behavior: none;
  margin: 0;
  padding: 0;
  background-color: black;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

body::-webkit-scrollbar {
  display: none; 
}
