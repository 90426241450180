@import url('https://fonts.googleapis.com/css2?family=Montagu+Slab:opsz,wght@16..144,100..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.main {
  position: relative;
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}