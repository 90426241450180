.right {
    display: block;
    position: relative;
    width: 720px;
    height: 1080px;
    background: url("../../public/right_paper.png");
    overflow: hidden;
}

.right_title {
    position: absolute;
    bottom: 270px;
    right: -315px;
    display: inline-block;
    font-family: "Montserrat", sans-serif;
    font-size: 100px;
    font-optical-sizing: auto;
    font-weight: 900;
    font-style: normal;
    color: #FF89CE;
    transform: rotate(-90deg);
}

.right_block {
    position: absolute;
    top: 0;
    right: 0;
    width: 37px;
    height: 290px;
    background-color: #FF89CE;
}

.right_video {
    position: absolute;
    top: 752px;
    left: 90px;
    width: 388px;
    height: 257px;
    background-color: #FFF6ED;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    cursor: pointer;
}

@media (max-width: 1439px) {
    .right {
        display: none;
    }
}